<template>
  <div class="login-section">
    <div class="login" v-if="!phoneLogin">
      <van-button class="submit-btn" block @click="handleLogin"
        ><span class="submit-btnText">手机号登录</span></van-button
      >
    </div>
    <div class="phoneLogin" v-else>
      <van-form @submit="onSubmit">
        <van-field
          v-model="loginForm.mobile"
          type="tel"
          name="mobile"
          label="手机号："
          placeholder="手机号"
          maxlength="11"
          :rules="[{ validator: verifyPhone, message: '请输入正确的手机号码' }]"
        />
        <van-field
          v-model="loginForm.smsCode"
          name="smsCode"
          label="验证码"
          placeholder="验证码"
          maxlength="6"
          :rules="[{ validator: verifyCode, message: '请填写6位验证码' }]"
        >
          <template #button>
            <span class="code" @click="getCode" v-if="!codeState"
              >获取验证码</span
            >
            <span class="code" v-if="codeState" style="color: #bebebe"
              >还剩{{ codeTime }}秒</span
            >
          </template>
        </van-field>
        <div style="margin: 30px 14px 0;">
          <van-button style="font-size:16px;"
            round
            block
            type="info"
            :loading="loading"
            native-type="submit"
            loading-text="登录中..."
            >登录</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { verifyLoginSmsCode, login } from "@/api/login.js";
import { Toast } from "vant";
import { getQueryVariable } from '@/utils/index.js'
export default {
  name: "",
  components: {},
  data() {
    return {
      phoneLogin: false,
      loginForm: {
        mobile: "",
        smsCode: "",
      },
      codeState: false,
      codeTime: 60,
      timer: null,
      phoneEmpty: false,
      codeEmpty: false,
      loading: false,
      getQueryVariable:getQueryVariable("openId")
    };
  },
  activated() {},
  mounted() {
    var openId = this.getQueryVariable || "";
    localStorage.setItem("openId", openId);
    console.log('登录页')
  },
  methods: {
    handleLogin() {
      this.phoneLogin = true;
    },
    async onSubmit(values) {
      console.log("onSubmit", values);
      this.loading = true;
      const { code, data } = await login(values);
      this.loading = false;
      if (code == 200) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("realName", data.realName);
        localStorage.setItem("nickname", data.nickname);
        localStorage.setItem("isOldUser", data.isWithBill ? 1 : "");
        if (this.$route.query.next_url) {
          document.location = decodeURIComponent(this.$route.query.next_url);
        } else {
          this.$router.replace({
            path: "/",
          });
        }
      }
    },
    async getCode() {
      if (this.phoneEmpty) {
        var that = this;
        const { code } = await verifyLoginSmsCode({
          mobile: this.loginForm.mobile,
        });
        if (code == 200) {
          this.codeState = true;
          that.timer = setInterval(() => {
            if (that.codeTime == 0) {
              clearInterval(that.timer);
              that.codeState = false;
              that.codeTime = 60;
            } else {
              that.codeTime--;
            }
          }, 1000);
        }
      } else {
        this.phoneEmpty = false;
        Toast("请先输入手机号");
      }
    },
    verifyPhone(val) {
      // var rule =
      //   /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(val);
      var rule = /^1\d{10}$/.test(val);
      if (rule) {
        this.phoneEmpty = true;
      } else {
        this.phoneEmpty = false;
      }
      return rule;
    },
    verifyCode(val) {
      return val.length == 6;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/style/variable.scss";
.login-section {
  height: 100%;
  .login {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-image: url("../../assets/img/login_bg.png");

    .submit-btn {
      position: absolute;
      bottom: 131px;
      right: calc(50% - 200px);
      width: 400px;
      height: 98px;
      background: linear-gradient(90deg, #E6A669 0%, #FFCE9B 52%, #E69A48 100%);
      box-shadow: 0 11px 30px 0 #0A0400;
      border-radius: 49px;
    }
    .submit-btnText {
      width: 160px;
      height: 44px;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC,serif;
      font-weight: 600;
      color: #130E00;
      line-height: 44px;
    }
  }
  .phoneLogin {
    height: 100%;
    padding: 4rem 40px;
    .code {
      color: #1677ff;
    }
  }
  .login-btn{
    font-size: 16px;
  }
  .van-cell{
    font-size: 16px;
    padding: 14px 16px;
  }
}
</style>